import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonTitle,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonListHeader
} from "@ionic/react";
import ReactPlayer from "react-player";
import { close } from "ionicons/icons";
import "./Schedule.css";

const Schedule = ({ asset, dismiss }) => {
  const [data, setData] = useState();
  const [replays, setReplays] = useState();
  const [showPlayer, setShowPlayer] = useState(false);
  const [choice, setChoice] = useState({});

  useEffect(() => {
    async function fetchData() {
      const result = await axios.get(
        "https://faithstream-server.herokuapp.com/api/church/schedule",
        {
          params: {
            member: asset.muxId
          }
        }
      );

      setData(result.data.upcoming);
      setReplays(result.data.replay);
    }
    fetchData();
  }, [asset]);

  const displayReplay = item => {
    console.log("displayReplay");
    setShowPlayer(true);
    setChoice(item);
  };

  return (
    <IonContent>
      {showPlayer ? (
        <React.Fragment>
          <IonToolbar>
            <IonButtons slot="secondary">
              <IonButton onClick={() => setShowPlayer(false)}>
                <IonIcon slot="icon-only" icon={close} color="dark" />
              </IonButton>
            </IonButtons>
            <IonTitle>{choice.title}</IonTitle>
          </IonToolbar>
          <ReactPlayer
            url={choice.streamUrl}
            controls
            width="100%"
            volume={null}
            muted
            playing
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <IonToolbar>
            <IonButtons slot="secondary">
              <IonButton onClick={dismiss}>
                <IonIcon slot="icon-only" icon={close} color="dark" />
              </IonButton>
            </IonButtons>
            <IonTitle>{asset.name}</IonTitle>
          </IonToolbar>
          <IonList class="list">
            {replays && (
              <IonListHeader>
                <IonLabel>Watch Replays:</IonLabel>
              </IonListHeader>
            )}
            {replays &&
              replays.map((item, index) => (
                <IonItem
                  class="item"
                  key={index}
                  button
                  detail={false}
                  onClick={() => displayReplay(item)}
                >
                  <IonLabel>{item.title}</IonLabel>
                </IonItem>
              ))}
            <IonListHeader>
              <IonLabel>Upcoming Schedule:</IonLabel>
            </IonListHeader>
            {data &&
              data.map((date, index) => (
                <IonItem class="item" key={index}>
                  <IonLabel>{date}</IonLabel>
                </IonItem>
              ))}
          </IonList>
        </React.Fragment>
      )}
    </IonContent>
  );
};

export default Schedule;
