export const videos = [
    {
        photoUrl:"https://images.unsplash.com/photo-1499209974431-9dddcece7f88?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80",
        name: "Kiko",
        title: "Inspiration",
        streamUrl:"https://stream.mux.com/DYSk02wWpCO4lKy2823R00lIyfR2JPqCeA.m3u8"
    },
    {
        photoUrl:"https://www.truli.com/sites/default/files/Dark%20Prince%20title_3.jpg",
        name: "Zola Levitt Presents",
        title: "The Dark Prince",
        streamUrl:"https://stream.mux.com/xAXhMVXWeyikGfFb6nEiLcTS36zOtnZQ.m3u8"
    }
]
