import {
  IonPage,
  IonContent,
  IonCard,
  IonCardContent,
  IonRow,
  IonCol,
  IonButton,
  IonInput,
  IonImg,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonToast
} from "@ionic/react";
import React, { useState } from "react";
import axios from "axios";
import "./Login.css";
// import { micOutline } from "ionicons/icons";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fullName, setFullName] = useState("");
  const [method, setMethod] = useState("signin");
  const [showUser, setShowUser] = useState(false);

  const login = async () => {
    if (email && password) {
      await axios
        .post("https://faithstream-server.herokuapp.com/signin", {
          email: email,
          password: password
        })
        .then(function(response) {
          console.log(response);
          setEmail("");
          setPassword("");
        })
        .catch(function(error) {
          console.log(error.response.data.message);
        });
    }
  };

  const register = async () => {
    if (email && password && fullName) {
      await axios
        .post("https://faithstream-server.herokuapp.com/signup", {
          email: email,
          password: password,
          fullName: fullName
        })
        .then(function(response) {
          console.log(response);
          setShowUser(true);
          setEmail("");
          setPassword("");
          setFullName("");
          setMethod("signin");
        })
        .catch(function(error) {
          console.log(error.response.data.message);
        });
    }
  };

  return (
    <IonPage id="main">
      <IonContent no-padding className="login-page">
        <IonToast
          isOpen={showUser}
          onDidDismiss={() => setShowUser(false)}
          header="User Has Been Created"
          message="You May Now Login"
          duration={2000}
          color="primary"
        />
        <div className="mainFirebase">
          <IonCard no-margin class="card">
            <IonCardContent>
              <IonRow>
                <IonCol className="row">
                  <IonImg
                    src="https://firebasestorage.googleapis.com/v0/b/test-798e3.appspot.com/o/FaithStream%2FFaith%20Stream.png?alt=media&token=668cbc06-d16b-4165-bb16-80a50b3f84a8"
                    class="img-logo"
                  />
                </IonCol>
              </IonRow>
              <IonRow style={{ paddingBottom: "20px" }}>
                <IonSegment
                  color="primary"
                  onIonChange={e => setMethod(e.detail.value)}
                  value={method}
                >
                  <IonSegmentButton value="signin">
                    <IonLabel className="segment-btn">Sign In</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton value="signup">
                    <IonLabel className="segment-btn">Register</IonLabel>
                  </IonSegmentButton>
                </IonSegment>
              </IonRow>
              {method === "signin" ? (
                <React.Fragment>
                  <IonRow>
                    <IonCol>
                      <IonInput
                        clearInput
                        type="email"
                        placeholder="Email"
                        class="input"
                        padding-horizontal
                        clear-input="true"
                        value={email}
                        onIonChange={e => setEmail(e.detail.value)}
                      />
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonInput
                        clearInput
                        type="password"
                        placeholder="Password"
                        class="input"
                        padding-horizontal
                        clear-input="true"
                        value={password}
                        onIonChange={e => setPassword(e.detail.value)}
                      />
                    </IonCol>
                  </IonRow>
                  {/* <IonRow>
                    <IonCol>
                      <IonText float-end color="medium">
                        <h6 no-margin text-end className="small">
                          Forgot Password?
                        </h6>
                      </IonText>
                    </IonCol>
                  </IonRow> */}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <IonRow>
                    <IonCol>
                      <IonInput
                        clearInput
                        type="string"
                        placeholder="Name"
                        class="input"
                        padding-horizontal
                        clear-input="true"
                        value={fullName}
                        onIonChange={e => setFullName(e.detail.value)}
                      />
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonInput
                        clearInput
                        type="email"
                        placeholder="Email"
                        class="input"
                        padding-horizontal
                        clear-input="true"
                        value={email}
                        onIonChange={e => setEmail(e.detail.value)}
                      />
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonInput
                        clearInput
                        type="password"
                        placeholder="Password"
                        class="input"
                        padding-horizontal
                        clear-input="true"
                        value={password}
                        onIonChange={e => setPassword(e.detail.value)}
                      />
                    </IonCol>
                  </IonRow>
                </React.Fragment>
              )}

              <IonRow style={{ paddingTop: "20px" }}>
                <IonCol>
                  {method === "signin" ? (
                    <IonButton
                      expand="block"
                      class="btn-transition"
                      onClick={login}
                    >
                      <strong className="btnText">Sign In</strong>
                    </IonButton>
                  ) : (
                    <IonButton
                      expand="block"
                      class="btn-transition"
                      onClick={register}
                    >
                      <strong className="btnText">Register</strong>
                    </IonButton>
                  )}
                </IonCol>
              </IonRow>
              <div className="line"></div>
            </IonCardContent>
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default LoginPage;
