import { IonContent } from "@ionic/react";
import React from "react";
// import { RouteComponentProps } from "react-router";
import Live from "../components/Live/Live";
import Vod from "../components/Vod/Vod";

import "./Page.css";

const Main = ({ show }) => {
  return (
    <IonContent fullscreen={true} class="content-main">
      {show === "live" ? <Live /> : <Vod />}
    </IonContent>
  );
};

export default Main;
