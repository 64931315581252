import React, { useState } from "react";
import { IonRow, IonGrid, IonCol, IonHeader, IonButton } from "@ionic/react";
import "./Date.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Date = () => {
  const [startDate, setStartDate] = useState();

  const submitDate = async () => {
      console.log(startDate);
    // const result = await axios(
    //     "https://faithstream-server.herokuapp.com/api/church/insert"
    //   );
  }
  return (
    <IonGrid style={{ marginTop: "200px" }}>
      <IonHeader style={{ color: "black" }}>Add Date</IonHeader>

      <IonRow>
        <IonCol>
          <DatePicker
            selected={startDate}
            onChange={date => setStartDate(date)}
            showTimeSelect
            timeIntervals={30}
            timeCaption="time"
            dateFormat="MMMM d, yyyy h:mm aa"
            placeholderText="Click to select time"
            withPortal
          />
          <IonButton onClick={submitDate}>Submit Date</IonButton>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default Date;
