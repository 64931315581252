import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  IonContent,
  IonCardTitle,
  IonRow,
  IonCol,
  IonGrid,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonText,
  IonModal,
} from "@ionic/react";
import { motion } from "framer-motion";
import "./Live.css";
import Player from "../Player/Player";
import Schedule from "../Schedule/Schedule";

// methods
import { addClick } from "../../api/clicks";

const Live = () => {
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [choice, setChoice] = useState({});

  // initial data fetch
  useEffect(() => {
    async function fetchData() {
      const result = await axios(
        "https://faithstream-server.herokuapp.com/api/church"
      );
      setData(result.data);
    }
    fetchData();
  }, []);

  //31 second polling for data
  useEffect(() => {
    async function fetchData() {
      const result = await axios(
        "https://faithstream-server.herokuapp.com/api/church"
      );
      setData(result.data);
    }

    const interval = setInterval(() => {
      fetchData();
    }, 31000);

    return () => clearInterval(interval);
  }, []);

  const memberClick = (member) => {
    setChoice(member);
    setShowModal(true);
    addClick(member);
  };

  const modalDismiss = () => {
    setShowModal(false);
  };

  const MemberCard = data.map((member, index) => (
    <IonCol key={index}>
      <IonCard
        class="card-basic"
        no-margin
        button
        onClick={() => memberClick(member)}
      >
        <img src={member.photoUrl} alt="user" className="member-img" />
        <IonCardHeader>
          <IonCardTitle class="card-title">{member.name}</IonCardTitle>
        </IonCardHeader>
        {member.online ? (
          <IonCardContent class="card-content">
            <IonRow>
              <IonCol>
                <IonText class="card-title">
                  {member.city}, {member.state}
                </IonText>
              </IonCol>
              <IonCol>
                <IonText class="card-status-online">Live</IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol style={{ background: "green", height: "25px" }}></IonCol>
            </IonRow>
          </IonCardContent>
        ) : (
          <IonCardContent class="card-content">
            <IonRow>
              <IonCol>
                <IonText class="card-title">
                  {member.city}, {member.state}
                </IonText>
              </IonCol>
              <IonCol>
                <IonText class="card-status-offline">Offline</IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol style={{ background: "red", height: "25px" }}></IonCol>
            </IonRow>
          </IonCardContent>
        )}
      </IonCard>
    </IonCol>
  ));
  return (
    <IonContent>
      <IonModal
        isOpen={showModal}
        swipeToClose={true}
        onDidDismiss={modalDismiss}
        cssClass="modal"
      >
        {choice.online ? (
          <Player asset={choice} />
        ) : (
          <Schedule asset={choice} dismiss={() => setShowModal(false)} />
        )}
      </IonModal>
      <IonGrid style={{ marginTop: "50px" }}>
        <h3 className="section-header">Texas</h3>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 3 }}
        >
          <IonRow class="row-member">{MemberCard}</IonRow>
        </motion.div>
      </IonGrid>
    </IonContent>
  );
};
export default Live;
