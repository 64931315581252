import React, { useState  } from "react";
import {
  IonContent,
  IonCardTitle,
  IonRow,
  IonCol,
  IonGrid,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonModal,
} from "@ionic/react";
import { motion } from "framer-motion";
import "./Vod.css";
import Player from "../Player/Player";

import { videos } from "../../constants/video-content";

const Vod = () => {
  const [showModal, setShowModal] = useState(false);
  const [choice, setChoice] = useState({});

  const memberClick = (member) => {
    setChoice(member);
    setShowModal(true);
  };

  const modalDismiss = () => {
    setShowModal(false);
  };

  const MemberCard = videos.map((member, index) => (
    <IonCol key={index}>
      <IonCard
        class="card-basic"
        no-margin
        button
        onClick={() => memberClick(member)}
      >
        <img src={member.photoUrl} alt="user" className="member-img" />
        <IonCardHeader>
          <IonCardTitle class="card-title">{member.name}</IonCardTitle>
          <IonCardTitle class="card-desc">{member.title}</IonCardTitle>
        </IonCardHeader>
        <IonCardContent class="card-content"></IonCardContent>
      </IonCard>
    </IonCol>
  ));
  return (
    <IonContent>
      <IonModal
        isOpen={showModal}
        swipeToClose={true}
        onDidDismiss={modalDismiss}
        cssClass="modal"
      >
        <Player asset={choice} />
      </IonModal>
      <IonGrid style={{ marginTop: "50px" }}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 3 }}
        >
          <IonRow class="row-member">{MemberCard}</IonRow>
        </motion.div>
      </IonGrid>
    </IonContent>
  );
};
export default Vod;
