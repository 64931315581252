import axios from "axios";

export const addClick = async member => {
    await axios({
        method: "PUT",
        url: 'https://faithstream-server.herokuapp.com/api/church/click',
        data: {
            id: `${member._id}`
        }
    })
};

export const addLiveViewer = async (asset, id) => {
    await axios({
        method: "PUT",
        url: 'https://faithstream-server.herokuapp.com/api/church/live',
        data: {
            asset: `${asset}`,
            id: `${id}`
        }
    })
};


