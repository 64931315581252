import React, { useState } from "react";
import {
  IonApp,
  IonRouterOutlet,
  IonHeader,
  IonToolbar,
  IonSegment,
  IonSegmentButton
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Route } from "react-router-dom";
import { UserProvider } from "./context/userContext";
// import InitializeFirebase from "./config/firebase/firebase";

// my components
import Main from "./pages/Main";
import Date from "./components/Date/Date";
import Login from "./pages/Login";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./App.css";

// const fb = InitializeFirebase();

const App = () => {
  const [segmentValue, setSegmentValue] = useState("live");
  const user = { email: "no@no.com", name: "Test Person" };

  if (!user) {
    return <Login />;
  }

  return (
    <UserProvider value={user}>
      <IonApp>
        <IonHeader style={{ background: "#231F37" }}>
          <IonToolbar>
            <IonSegment
              onIonChange={(e) => setSegmentValue(e.detail.value)}
              class="ionsegmentNetflix"
              value={segmentValue}
            >
              <IonSegmentButton value="live" class="ionsegmentbuttonNetflix">
                Live Streams
              </IonSegmentButton>
              <IonSegmentButton value="vod" class="ionsegmentbuttonNetflix">
                Video on Demand
              </IonSegmentButton>
            </IonSegment>
          </IonToolbar>
        </IonHeader>
        <IonReactRouter>
          <IonRouterOutlet id="main">
            <Route
              path="/"
              render={() => {
                return <Main show={segmentValue} />;
              }}
              exact
            />
            <Route path="/date" component={Date} />
          </IonRouterOutlet>
        </IonReactRouter>
      </IonApp>
    </UserProvider>
  );
};

export default App;
