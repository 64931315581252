import React, { useState, useEffect } from "react";
import axios from "axios";
import { IonRow, IonGrid, IonCol, IonHeader } from "@ionic/react";
import ReactPlayer from "react-player";

// methods
import { addLiveViewer } from "../../api/clicks";

const Player = ({ asset }) => {
  const [data, setData] = useState();

  useEffect(() => {
    async function fetchData() {
      const result = await axios.get(
        "https://faithstream-server.herokuapp.com/api/church/asset",
        {
          params: {
            member: asset._id
          }
        }
      );
      setData(result.data);
    }
    fetchData();
  }, [asset]);

  useEffect(() => {
    if (data) {
      addLiveViewer(data, asset._id);
    }
  }, [data, asset]);

  return (
    <IonGrid>
      <IonRow>
        <IonCol>
          <IonHeader style={{ color: "white" }}>{asset.name}</IonHeader>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <ReactPlayer
            url={asset.streamUrl}
            controls
            width="100%"
            volume={null}
            muted
            playing
          />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default Player;
