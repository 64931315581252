import React from "react";

const UserContext = React.createContext({ user: null });

export const UserProvider = UserContext.Provider;
export const UserConsumer = UserContext.Consumer;

export default UserContext;

// export const userContext = React.createContext({
//   user: null
// });

export const userSession = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { user } = React.useContext(UserContext);
  return user;
};
